.trocarSenha{
    max-width: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer
}

@media screen and (max-width: 991px){
    .trocarSenha{
        left: 10px;
        top: 15px;
        max-width: 30px;
    } 
}