.tudo {
    min-height: calc(100vh - 90px);
}

/* Menu principal */
.menu ul {
    padding-left: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 15px;
  }
  .menu ul li {
    margin-left: 35px;
    position: relative;
  }
  .menu ul li:first-child {
    margin-left: 0;
  }
  .menu ul li a {
    font-size: 16px;
    color: #a8a8a8
  }
  .menu ul li .seta-menu {
    -webkit-transform: transitionX(-100%);
            transform: transitionX(-100%);
    transition: all 0.5s;
    border-bottom: 1px solid #363d69;
    width: 0%;
    position: relative;
    transition: width 0.5s;
  }
  .menu ul li .seta-menu::after {
    content: url("/images/seta-menu.png");
    width: 5px;
    height: 5px;
    background-size: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: -9px;
    right: 0;
    opacity: 0;
  }
  .menu ul li:hover a, .menu ul li a.active {
    color: #363d69;
    font-weight: bold;
    text-decoration: none;
  }
  .menu ul li:hover .seta-menu {
    width: 100%;
    -webkit-transform: transitionX(0%);
            transform: transitionX(0%);
  }
  .menu ul li:hover .seta-menu::after {
    opacity: 1;
  }

  .menu ul .active + .seta-menu {
    width: 100%;
    -webkit-transform: transitionX(0%);
            transform: transitionX(0%);
  }

  .menu ul .active + .seta-menu::after {
    opacity: 1;
  }

  .rodape{
    margin-top: 40px;
  }

  @media screen and ( max-width: 1200px ){
    .menu ul li a {
      font-size: 15px;
    }
  
  }

  