.galeriaControle .galeria-container{
    display: flex;
    flex-wrap: wrap;
}

.galeriaControle .galeria-container.fotos .foto-item {
    background-image: url("/images/banner-home.jpg");
    width: 100%;
    padding-bottom: 56%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .galeriaControle .galeria-container.fotos .foto-item2 {
    background-image: url("/images/banner2.jpg");
    width: 100%;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .galeriaControle .galeria-container.fotos > .galeria-item {
    cursor: pointer;
    overflow: hidden;
    flex: 1;
    min-width: 250px;
    max-height: 500px;
    border:1px solid white
  }

  .galeria-modal-container img{
    border: 3px solid white;
    padding: 10px;
  }



  .galeria-modal-container .fechar{
    color: white;
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 33;
  }


  @media screen and (max-width: 576px) {
    .galeriaControle .galeria-container.fotos > .galeria-item {
      cursor: pointer;
      overflow: hidden;
      flex: 1;
      min-width: 100px;
      max-height: 120px;
      border:1px solid white
    }
  }
