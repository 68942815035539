.image-preview-input {
    position: relative;
	overflow: hidden;
	margin: 0px;    
    color: #333;
    background-color: #fff;
    border-color: #ccc;    
}
.image-preview-input input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	margin: auto;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
	width: 100%;
    height: 100%;
}
.image-preview-input-title {
    margin-left:2px;
}

.modalMetagram .header-metagram{
    border-bottom: 1px solid #d6d6d6;
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.modalMetagram .header-metagram button{
	flex: 1;
    margin-right: 5px;
	font-size: 16px;
	margin-bottom: 15px;
}

.body-metagram .texto label{
	font-size: 16px;
}

.body-metagram .texto textarea{
	min-height: 200px;
}

.body-metagram .texto .btncores{
	margin-bottom: 20px;
}

.body-metagram .texto .btncores button{

	margin-right: 8px;
}

.body-metagram div.image-preview-input{
	border: 2px dashed #f25d21;
	max-width: 200px;
	margin: auto;
	padding-top: 100px;
	padding-bottom: 100px;
	text-align: center
}

.body-metagram .image-preview .input-group-btn{
	width: 200px;
}

.body-metagram .wrapper-loader{
	margin-top: 20px;
    border-top: 1px solid #d6d6d6;
    padding: 15px 0;
}

.modalMetagram{
	margin-top: 40px;
}

.modalMetagram .header-metagram button{
	background-color: #afafaf !important;
}

.modalMetagram .header-metagram button.btn-ativo{
	background-color: #363d69 !important;
}

textarea.conteudo-box-home-position-relative{
	position: relative;
}

.conteudo-box-home-position-relative{
	position: relative;
}

textarea.compartilhar-texto::-webkit-input-placeholder {
	color: #fff;
}

textarea.compartilhar-texto:-moz-placeholder { /* Firefox 18- */
	color: #fff;
}

textarea.compartilhar-texto::-moz-placeholder {  /* Firefox 19+ */
	color: #fff;
}

textarea.compartilhar-texto:-ms-input-placeholder {  
	color: #fff;
}

textarea.compartilhar-texto::placeholder{
	color: #fff;
}

.wrraperMsgModal{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	opacity: 0;
	transition: 1.4s
}

.wrraperMsgModal.sucesso{
	background: #016a01f2;
	opacity: 1;
}

.wrraperMsgModal.erro{
	background: green
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color:white;
  }
  ::-moz-placeholder { /* Firefox 19+ */
	color:white;
  }
  :-ms-input-placeholder { /* IE 10+ */
	color:white;
  }
  :-moz-placeholder { /* Firefox 18- */
	color:white;
  }
  textarea::-webkit-input-placeholder {
	color:white;
  }
  
  textarea:-moz-placeholder { /* Firefox 18- */
	color:white;  
  }
  
  textarea::-moz-placeholder {  /* Firefox 19+ */
	color:white;  
  }
  
  textarea:-ms-input-placeholder {
	color:white;  
  }
  
  textarea::placeholder {
	color:white;  
  }
  