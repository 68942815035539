

.modalConteudo{
    position: relative;
    width: 95%;
    height: 90vh;
    padding-top: 20px;
    margin: auto
}

.modalConteudo > *{
    padding-left: 0;
    padding-right: 0
}

.modalConteudo >  div{
    height: 100%;
}

.glyphicon{
    margin-left: 10px;
}

.conteudo-box-home{
    background-color: white;
    padding: 20px 50px;
    border: 4px solid #f25d21;
    box-shadow: 4px 6px 1px #143a5f;
    max-width: 720px;
    margin: auto;
}

.conteudo-box-home img{
    max-width: 100%;
    height: auto;
    display: block
}

.modalConteudo .fechar{
    z-index: 9999;
    position: sticky;
    top: 20px;
    right: 4px;
    color: white;
    font-size: 32px;
    cursor: pointer;
    float: right;
}

.lista-respostas .minhaResposta {
    background-color: #363d69!important;
    margin-left: 25px;
}

.lista-respostas .minhaResposta .informacoesForum{
    color: white;
}

.listaDeComentarios .minhaResposta .comentImg{
    box-shadow: 2px 2px 5px #505050;
}

.respostas .material-icons{
    color: #d01f1f;
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
}

@media screen and (max-width: 768px){
    .conteudo-box-home{
        padding: 20px 20px;
    }
    .conteudo-box-home p{
        text-align: left;
    }
}

@media screen and (max-width: 414px){
    .conteudo-box-home{
        padding: 20px 5px;
    }   
}

.listaDeComentarios .comentImg{
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    box-shadow: 3px 3px 3px lightgray;
    margin-right: 15px
}

.listaDeComentarios .lista{
    padding-left: 0
}

.listaDeComentarios .lista li{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e9e4e4;
    box-shadow: 2px 2px 3px #e9e9e9;
    padding: 10px;
    border-radius: 10px;

}
.listaDeComentarios .lista li p{
    margin-bottom: 0;
    font-size: 14px;
    opacity: 0.7;
    font-style: italic
}
.listaDeComentarios span{
    font-size: 12px
}
.listaDeComentarios .nome{
    font-weight: bolder
}

.avliarMP{
    position: relative;
    left: 0;
    color: #868686;
    padding: 10px;
    background-color: #f6f6f6;
    border-radius: 10px;

}


.avliarMP i{
    color: lightgray;
    cursor: pointer;
}
.avliarMP i:hover{
    color: #f25d21;
    cursor: pointer;
    transition: .5s
}
.avliarMP i.ativo{
    color: #f25d21
}

.curtoQuotes{
    border-left: 5px solid #d3d3d3;
    box-shadow: inset 2px 2px 14px #ebebeb, 2px 2px 12px #c7d5e7;
    padding: 20px;
}
