.listaBox{
    cursor: pointer;
}
.tracado{
    border-bottom: 1px solid #ededed;
}
.tracado-titulo{
    margin-bottom: 0
}

.conteudo-box-home #conteudoMain iframe{
    width: 100%!important;
}

