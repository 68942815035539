@keyframes jump {
    0%   {transform: translate3d(0,0,0) scale3d(1,1, 1);}
    40%  {transform: translate3d(0,20%,0) scale3d(.9,1.3, 1);}
    100% {transform: translate3d(0,100%,0) scale3d(1.3,.7, 1);}
  }
  .webNarioContent{
    position: fixed;
  }
  .webNarioContent .webnarioIcon{
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 50px;
    height: 50px;
    transform-origin: 50% 50%;
    animation: jump .6s linear alternate infinite;
    border-radius: 50%
}

.webNarioContent .texto{
    position: fixed;
    right: 70px;
    bottom: 40px;
    background-color:white;
    width: 120px;
    border:2px solid lightgray;
    padding:10px;
    border-radius: 20px
}

.webNarioContent p{
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    color: black;
    font-weight: bolder
}
.webNarioContent .texto::after{
    position: absolute;
    right: -2px;
    content: " ";
    bottom: -2px;
    background-color: white;
    border-right: 2px solid lightgray;
    border-bottom: 2px solid lightgray;
    width: 20px;
    height: 20px;
    z-index: 3;
}

.webNarioContent .sombra{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    transform-origin: 50% 50%;
    animation: jump .6s linear alternate infinite;
    border-radius: 50%

}