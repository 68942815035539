.superAdmin .admin{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #0057a4;
    padding: 8px;
    box-shadow: 0px 0px 3px #adadad;
    flex-wrap: wrap;
}

.superAdmin .escolherObra{
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.superAdmin .escolherObra p{
    margin-right: 15px;
}

.superAdmin p{
    margin-bottom: 0;
    color: white;
}

.superAdmin .escolherObra .form-group{
    margin-bottom: 0;
}

.superAdmin{
    position: absolute;
    width: 100%;
    z-index: 999;
    transform: translate(0,-100%);
    opacity: 0;
    transition: 1.5s;
}

.abrirAdmin{
    color: #0057a4;
    display: flex;
    justify-content: center;
}

.abrirAdmin .material-icons{
    font-size: 30px!important;
    animation: piscarMenu 2s infinite;
    cursor: pointer;
}

.superAdmin-ativo{
    transform: translate(0,0);
    opacity: 1;
}

@keyframes piscarMenu{
    from{opacity: 0; transform: translate(0,-100%)}
    to{opacity: 1;transform: translate(0,0)}
}

.escolherObra .fechar-admin{
    background-color: transparent;
    border: none;
    color: white;
    margin-left: 40px;
    display: flex;
}

.escolherObra .fechar-admin .material-icons{
    font-size: 28px;
}

.bg-abrir-Menu-super{
    background-color: white;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 5px lightgrey;
    cursor: pointer;
}

.escolherObra .obra-admin{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 579px){
    .titulo-admin{
        display: none;
    }
    .superAdmin .escolherObra{
        width: 100%;
        justify-content: space-between;
    }
}