.signa-wraper{
  background-position: center center;
  background-size: contain
}
.signa-wraper .signa{
  background-color: white;
  padding: 70px 50px 110px 50px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 10px solid #363d69;
  border-right: 10px solid #363d69;
  border-top: 10px solid #363d69;

  
}

.titulos-signa{
  padding: 10px 20px;
  margin-top: 40px;
  background-color: rgba(255, 255, 255, 0.8) 

}

.signa-grande{
  margin-top: 30px
}

.signa h1{
    font-weight: bolder;
    padding-bottom: 30px
}
.signa h5{
  font-weight: bolder; 
}
.signa-pequeno{
  height: 100%;
  padding: 0;
  position: relative;
  justify-content: center;
  background-position: center;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}
.signa-pequeno .signa-wraper{
  position: relative;
  align-self: center;
}
.signa-pequeno img{
  position: absolute;
  align-self: center;
}

.signa-pequeno .titulos-signa{
  margin-top: -40px;
  margin-top: -40px;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 320px;
}

.titulo-signa-pequeno{
  padding-top: 30px;
  text-align: right;
  padding-right: 25%;
}

.signa-pequeno .signa-wraper .signa{
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.signa-pequeno h5{
  font-size: 18px
}

.signa-pequeno .pinguelo{
  margin-top: -11px;
}

.signa-pequeno .signa-wraper .signa h1{
  padding-bottom: 10px;
}    

.signa-pequeno .signa-wraper .signa h5{
  font-size: 22px
}  

.signa-pequeno{
  position: relative;
}

.signa-pequeno .bandeira img{
  position: absolute;
  top: 85px;
}

.signa-grande{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 900px;
  background-position: center;
  background-repeat: no-repeat;
}

.signa-grande img{
  height: 900px;
  max-width: 900px;
  width: 100%;
}

.signa-grande .signa-wraper{
  position: absolute;
  max-width: 400px;
  text-align: center;
      left: 50%;
    margin-left: -150px;
}

.signa-grande .titulos-signa{
margin-top: 80px;
}

.signa-grande .titulos-signa h5{
  font-size: 16px
}

.signa-pequeno .titulo-signa h4 {
  padding-top: 76px;
}

@media screen and (max-width: 1199px){
  .titulo-signa-pequeno{
    padding-top: 50px;
    padding-right: 22%;
    font-size: 25px
  }
  .signa-pequeno .titulos-signa{
    margin-top: -10px;
  }
  .titulos-signa{
    padding: 5px 10px;
  }
  .signa-grande .titulos-signa{
    margin-top: 150px;
  }
  .signa-grande .titulos-signa{
    background-color: transparent;
  }
}

@media screen and (max-width: 991px){

  .titulo-signa-pequeno{
    padding-top: 50px;
    padding-right: 35%;
    font-size: 30px
  }
  .signa-pequeno{
    height: 100vh;
  }
  .signa-pequeno .titulos-signa{
    padding: 0 40px;
    margin-top: 5%;
  }

  .signa-pequeno .titulos-signa h4{
    padding-top: 45px;
  }
  
  .signa-pequeno img{
    align-self: flex-start;
    margin-top: 20px;
  }
  .signa-pequeno .signa-wraper{
    align-self: flex-start;
  }
  .signa-grande .titulos-signa{
    margin-top: 40px;
  }
  .signa-grande{
    background-position: top;
  }
}
@media screen and (max-width: 700px){
  .titulo-signa-pequeno{
    padding-top: 50px;
    padding-right: inherit;
    font-size: 30px;
    text-align: center
  }
  .signa-grande .signa-wraper{
    margin-left: -80px;
    max-width: 200px;
  }
  .signa-grande .titulos-signa > *{
    font-size: 18px;
  }
}

@media screen and (max-width: 576px){
  .titulo-signa{
    font-size: 14px !important
  }
  .signa-wraper{
      padding: 20px;  
  }
  .signa-wraper .signa{
      padding: 40px 30px;  
  }
  .signa {
    padding: 50px;
  }
  .signa-pequeno .signa-wraper{
    top: 5%;
  }
  .signa-grande .titulos-signa{
    padding: 0 10px;
  }
  .signa-pequeno .titulos-signa {
    margin-top: 30px;
  }
  .signa-pequeno .titulos-signa h4 {
    margin-top: 0;
    text-align: center;
    padding-right: 0;
  }
  .signa-grande .titulos-signa{
    margin-top: 220px;
  }
  .signa-grande{
    background-position: top;
  }
  .signa-grande .titulos-signa{
    margin-top: 0;
  }
}

@media screen and (max-width: 425px){
  .signa-grande .signa-wraper {
    left: 50%;
    margin-left: -80px;
  }
  .signa-grande .titulos-signa > *{
    font-size: 16px;
  }
}
@media screen and (max-width: 375px){
  .titulo-signa{
    font-size: 12px !important
  }
}

@media screen and (max-width: 320px){

  .signa-pequeno .signa-wraper{
    padding: 0;
  }
  .signa-grande .signa-wraper{
    margin-left: -140px;
    max-width: 300px;
  }
  .signa-grande .titulos-signa > *{
    font-size: 14px;
  }
  .signa-grande .titulos-signa{
    background-color: white;
  }
  .signa-grande .titulos-signa {
    margin-top: 20px;
  }
  .signa-grande .titulos-signa {
    padding: 5px 10px;
  }
}

.signa .titulo-signa {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}