#basilar .galeria-modal .pesquisaBasilar .basiliar-wraper .box-home{
    width: 100% !important
}
#basilar .box .box-home{
    width: 100% !important
}

.modalBasilar{
    position: relative;
    width: 95%;
    height: 90vh;
    padding-top: 20px
}

.backdrop-modal .fechar{
    z-index: 9999;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    right: 4px;
    color: rgb(1,84,168);
    font-size: 32px;
    cursor: pointer;
    text-align: center;
    float: right;
    margin-right: 40px;
    background-color: white;
    width: 30px;
    height: 30px;
    background-position: center;
    border-radius: 50%;
    line-height: 30px;
    box-shadow: 1px 1px 5px #656565;
}

#modalBasilarFundo{
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 1px 6px 1px #46464666;
    border-radius: 4px;
}

@media screen and (max-width: 850px){
    .backdrop-modal .fechar{
        margin-right: 10px;
    }
}

@media screen and (max-width: 767px){
    .backdrop-modal .fechar{
        margin-right: 5px;
        top: 6px;
    }
}

@media screen and (max-width: 500px){
    .backdrop-modal .fechar{
        margin-right: 0;
    }
}
@media screen and (max-width: 1640px){
    .item-basilar p{
        max-width: 80%;
        overflow: hidden;
    } 
}

@media screen and (max-width: 1506px){
    .basiliar-wraper .basilar{
        column-count: 1;
    }
}

.basiliar-wraper{
    text-align: left;
}
.item-basilar p{
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 17px;
}
.basilarW{
    width: 100%
}

.divBG{
    background-color: lightgrey;
    height: 20px;
}

.divBG .divCor{
    height: 100%;
}

 .legenda .item-legenda{
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 10px;
}

.legenda .item-legenda p{
    margin-bottom: 0;
}

.legenda .item-legenda .numero-legenda{
    margin-right: 10px;
    padding: 5px;
    width: 100px;
    text-align: center;
    color: #fff;
}

.modalBasilar .conteudo-box-home{
    background-color: white;
    padding: 20px;
    border: 4px solid #f25d21;
    box-shadow: 4px 6px 1px #143a5f;
    margin: auto;
    width: 100%;
}

.modalBasilar .amarelo{
    background-color: #d1c71e;
    color: white !important
}

.modalBasilar .laranja{
    background-color: #fda51a;
    color: white !important
}

.modalBasilar .azul{
    background-color: #0054a8;
    color: white !important
}

.modalBasilar .amarelo,  .modalBasilar .azul,.modalBasilar .laranja{
    width: 120px;
    text-align: center;
    padding:10px;
    margin-bottom: 10px
}

.modalBasilar .amarelo p,  .modalBasilar .azul p,.modalBasilar .laranja p{
    margin-bottom: 0;
    padding-bottom: 0
}

/* Animations */
.basilar-animation{
    opacity: 0;
    transition: 2s;
}

.basilar-state-entered{
    opacity: 1;
}

.basilar-state-entering{
    opacity: 1;
}

.basilar-state-exiting{
    opacity: 0;
}
.basilar-state-exited{
    opacity: 0;
}