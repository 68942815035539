

#basilar .galeria-modal .pesquisaBasilar .box .item-box > p{
    margin-bottom: 0;
    padding-bottom: 0
}

.item-basilar {
    margin-bottom: 30px;
}

.basiliar-wraper .basilar{
    column-count: 2;
}

#basilar{
    column-count: 2;
}

.basiliar-wraper .primeiro-filho{
    column-span: all;
}