.apoio .logosApoio ul li img{
    max-width: 150px;
}

.apoio .logosApoio ul{
    list-style: none;
    display: flex;
    justify-content: center;
    flex:1;
    flex-wrap: wrap;
    padding-left: 0;
}

.apoio .logosApoio ul a{
    display: flex;
    padding: 10px;
    flex: 1;
    margin: 10px;
    cursor: pointer;
    justify-content: center;
}

.pageMetaconexao .orienTacaoEApoio{
    padding: 20px;
    padding-top: 50px;
}

.ul-apoio{
    display: flex;
    width: 100%;
}

.ul-apoio li{
    padding: 5px;
}

.ul-apoio .btn-atual{
    width: 100%;
    margin-bottom: 10px;
}

.apoio .logosApoio ul li img{
    max-height: 80px;
}

.apoio .logosApoio ul li:hover{

}

.agendas .agenda{
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.agendas .agenda > *{
    margin-bottom: 10px;
}

.agendas .agenda::after{
    /*content: url(/images/seta-box.png);*/
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
    position: absolute;
    right: -3px;
    transform: rotate(90deg);
    transition: 1s;
    top: 19px;
}

.agendas .agenda-ativo::after {
    transform: rotate(-180deg);
}

.agendas li{
    position: relative;
    padding: 10px;
    cursor: pointer;
    padding-bottom: 0;
}


.agendas{
    list-style: none;
    padding-left: 0;
}

.agendaWrapper hr{
    margin-top: 10px;
    margin-bottom: 5px;
}

.agendaWrapper{
    border: 1px solid lightgray;
    padding: 15px;
    margin-bottom: 30px;
    box-shadow: 0 0 5px lightgrey;
}

.agendas .btn-participar{
    display: flex;
    align-items: center;
}

.agendas .btn-participar .material-icons{
    margin-right: 5px;
    padding: 5px 0;
    font-size: 22px;
}

@media screen and (max-width: 576px){
    .apoio .logosApoio ul li{
        margin-right: 5px;
        width: 100%;
    }

    #Apoio .conteudo-box-home{
        padding: 15px 15px;
    }
}
@media screen and (max-width: 320px){
    #Apoio .conteudo-box-home{
        padding: 20px 20px;
    }
    .agendas .agenda::after{
        top: 22px;
    }
    .apoio .logosApoio ul li{
        margin-right: 0;
    }
}
