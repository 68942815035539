.logoMatagram img{
    width: 200px;
    margin-top: 40px;
}

.modalMetagram{
    position: relative;
}

.metagramItem{
    position: relative;
    margin-bottom: 30px;
    box-shadow: 2px 1px 4px 2px #31313133;

}

.deletarElemento{
    transition: .7s;
    max-height: 800px;
}

.deletarElemento.deletado{
    transform: scale(0);
     max-height:0
}

.metagramItem .deletar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ff0000ad;
    z-index: 2;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0
}
.metagramItem .deletar .btn{
    box-shadow: 0;
    border:1px solid white
}



.body-metagram textarea{
    text-align: center;
    font-size: 1.2em;
    padding: 20px
}

.body-metagram .compartilhar-texto{
    color: white;
}

.logoMatagram .btn-mais{
    background-image: url('/images/btn-mais.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 2;
}

.logoMatagram{
    position: relative;
}

.metagramItem .header-box{
    display: flex;
    background-color: #fff;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c9c9c9;
}

.header-box .info-post{
    display: flex;
    align-items: center;
}

.header-box .info-post img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%; 
}

.header-box .sentimentos{
    display: flex;
    align-items: center;
}

.header-box .sentimentos img{
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.header-box .titulo-metagram{
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
}

.header-box p{
    margin-bottom: 0;
    color: #00ae4d;
}
.header-box {
    position: relative;
}
.header-box .deletar-post{
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 0;
    box-shadow: 0 0 5px gray;
    font-size: 18px;
}

.bold{
    font-weight: bold;
}

.body-box{
    text-align: center;
    color: #fff;
    min-height: 400px;
    margin-top: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.body-box .conteudo{
    margin-bottom: 20px;
}

.body-box .autor{
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -60px;
}

.body-box .autor > *{
    line-height: 25px;
    font-weight: bold;
}

.body-box .autor span{
    font-size: 14px;
}

.footer-box{
    background-color: #fff;
    text-align: right;
    padding: 15px;
}

.body-box .imagem{
    background-image: url('/images/banner-home.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    min-height: 400px;
    background-color: black;
}

.tipo-full{
    padding: 0;
}

.footer-box .comentario{
    text-align: left;
    max-height: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
}
.footer-box .comentario-ativo{
    max-height: 100%;
}

.footer-box .comentario .fade-comentario{
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0;
}

.footer-box .links {
    display: flex;
    justify-content: space-between;
} 

#video{
    min-height: 0;
}

.radar{
    background-color: #00ae4d;
    width: 100px;
    height: 100px;
    position: absolute;
    top: -15px;
    right: -25px;
    border-radius: 50%;
    animation: radar infinite 1.5s;
}



  .metanoia-animation-enter {
    opacity: 0;
  }
  .metanoia-animation-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .metanoia-animation-exit {
    opacity: 1;
  }
  .metanoia-animation-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }


@keyframes radar{
    from {transform: scale(0);opacity: 0.5;}
    to{ transform: scale(50px);opacity: 0;}
}

.lerMais{
    background-color: transparent;
    border: none;
}

.lerMais-ativo{
    visibility: hidden;
}

@media screen and (max-width: 992px) {
    .logoMatagram .btn-mais {
      width: 30px;
      height: 30px;
      top: 0;
      right: 16px;
    }
    .radar{
        top: -25px;
        right: -8px;
        width: 80px;
        height: 80px;   
    }
    @keyframes radar{
        from {transform: scale(0);opacity: 0.5;}
        to{ transform: scale(20px);opacity: 0;}
    }
    
  }

  
@media screen and (max-width: 575px) {
    .webNarioContent .texto{
        visibility: hidden;
    }   
}

.btn-confirmar-email{
    background-color: #363d69;
    color: #fff!important;
    padding: 10px;  
}