
.mapaTravessia .boxDevir{
    min-height: 500px;
    padding: 40px
}

.avaliar{
    height: 20px;
    background-color: #f5f5f5;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
    width: 20px;
    height: 20px;
    border: 1px solid lightgray
}



.avaliacao{
    display: flex;
    align-items: center;
}

.avaliar-ativo{
    width: 20px;
    height: 20px;
    background-image: url('/images/pe2.png');
    font-size: 12px;
    position: relative;
    color: #fff;
    background-color: #337ab7;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,0.15);
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;

}
.avaliar-ativo span{
    width: 100%;
    height: 5px;
    position: absolute;
    left: 0;
    background-color: #2df8ff;
    border-radius: 50px;
    top: 6px;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    opacity: 0.8;
}
