


.login-wrraper {
    min-height: calc(100vh);
  }
.loginDevir{
    opacity: 0;
    transition: 2s ease
}

.loginDevir.login-entered{
    opacity: 1;
}

.alterar-senha{
    background-color: white;
    margin-top: 20px;
    box-shadow: 0 0 5px #7c7c7c;
    padding: 20px
}

.alterar-senha .erro-senha{
    padding-top: 10px;
    color: red;
}

.modal-senha{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.btn-none{
    border: none;
    background-color: transparent
}