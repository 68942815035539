.livretes{
    display: flex;
    flex-wrap: wrap;
}
.livretes > *{
    width: 25%;
    min-width: 200px;
}


