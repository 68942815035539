.lista-respostas .respostas {
    background-color: #eaeaea;
    border-radius: 3px;
    padding: 15px;
    padding-bottom: 20px;
    position: relative;
    box-shadow: -1px 1px 8px 0px #c7c6c6;
    margin-bottom: 30px;
    border: 1px solid #b9b9b9;
}
.respostas p {
    margin-bottom: 5px;
}

.respostas .informacoesForum img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.respostas .informacoesForum{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.respostas .informacoesForum .inforFiliado{
    display: flex;
    align-items: center;

}

.respostas .respostaFiliado{
    background-color: white;
    box-shadow: 0 0 3px #afafaf;
    padding: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(112, 112, 112, .3);
    overflow: hidden;
    box-sizing: border-box;
}

.respostas .respostaFiliado::after{
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-color: white;
    border-left: transparent;
    border-top: transparent;
    border-right: 1px solid rgba(112, 112, 112, .3);
    border-bottom: 1px solid rgba(112, 112, 112, .3);
    transform: rotate(45deg);
    margin-left: 15px;
    position: absolute;
    left: 14px;
}

.respostas .digitarResposta{
    margin-top: 20px;
}

.respostas .add-comentario img{
    width: 30px;
    height: 30px;
}

.respostas .add-comentario{
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.forum .infoUltimaMsg img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.forum {
    background-color: #fff;
    box-shadow: 0px -2px 11px #afafb3;
    border-radius: 5px;
    width: 100%;
    padding-bottom: 15px;
    margin-top: 30px;
}

.forum .infoUltimaMsg{
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.forum .infoTopico{
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    align-items: center;
}

.forum .infoUltimaMsg p{
    margin-bottom: 0;
    color:#a8a8a8;
    font-size: 14px;
}

.forum .infoTopico > *,
.forum .infoTopico .infoQuant > *{
    display: flex;
}

.forum .infoTopico .infoQuant > *{
    margin-right: 20px;
    color: #a8a8a8;
}

.forum .infoTopico p{
    margin-left: 10px;
    margin-bottom: 0;
}

.forum .infoTitulos{
    padding: 15px;
}

.forum a:hover{
    text-decoration: none;
}

.criarTopico .pesquisar{
    display: flex;
    align-items: center;
}

.criarTopico .pesquisar i{
    color: white;
    background-color: #363d69;
    padding: 10px 16px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #363d69;
}

.criarTopico .input-lg{
    border-radius: 0;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.criarTopico .btn-mais {
    background-image: url(/images/btn-mais.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    z-index: 2;
}

.criarTopico .radar{
    top: -25px;
    left: -10px;
}

@media screen and (max-width: 991px){
    .criarTopico .radar{
        top: -15px;
        left: 0;
    }
}

@media screen and (max-width: 768px){
    .forum{
        margin-top: 20px;
    }
}

@media screen and (max-width: 575px){
    .lista-respostas .btn-responderFiliado{
        width: 100%;
        margin-top: 20px;
    }
    .lista-respostas.comentarios{
        padding: 5px;
    }
    .responder-topico #topico{
        font-size: 26px;
    }
    .titulo-topico{
        font-size: 18px; 
    }
}

@media screen and (max-width: 375px){
    .respostas .informacoesForum .inforFiliado{
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 320px){
    .respostas .informacoesForum .inforFiliado{
        margin-top: 10px;
        flex-wrap: wrap;
    }
    .responder-topico #topico{
        font-size: 22px;
    }
    .titulo-topico{
        font-size: 16px; 
    }
}
/*Respostas dos Topicos*/
