@keyframes rotate{
    100%{
        transform: rotate(319deg)
    }
}

.loaderWrapper{
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
}
.loaderContent{
    max-width: 80px;
    border: none !important;
    animation: rotate 1.5s infinite ease;
    box-shadow: 0 0 0 0;
    margin: auto
}