.logo-svg {
  max-width: 120px;
  margin: auto;
  margin-top: 30px;
}
.st0 {
  fill: #232d4b;
}
.footer-texto {
  font-style: italic;
  font-size: 9px;
  opacity: 0.6;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 100;
}
.logo-padrao,
body.meta-gestao .logo-skin,
body.meta-gestao .logo-intranet,
body.alumiar .logo-skin,
body.alumiar .logo-intranet,
body.meta-riqueza .logo-skin,
body.meta-riqueza .logo-intranet,
body.limiar .logo-skin,
body.limiar .logo-intranet,
body.academia .logo-skin,
body.academia .logo-intranet,
body.paidea .logo-skin,
body.paidea .logo-intranet,
body.metanoia .logo-skin,
body.metanoia .logo-intranet,
body.devir .logo-skin,
body.devir .logo-intranet {
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  background-size: contain;
  background-position: center center;
}
@font-face {
  font-family: 'Flexo-Regular';
  src: url("/fonts/Flexo-Regular.eot?#iefix") format('embedded-opentype'), url("/fonts/Flexo-Regular.otf") format('opentype'), url("/fonts/Flexo-Regular.woff") format('woff'), url("/fonts/Flexo-Regular.ttf") format('truetype'), url("/Flexo-Regular.svg#Flexo-Regular") format('svg');
  font-weight: normal;
  font-style: normal;
}
.text-justify {
  text-align: justify;
}
.text-bold {
  font-weight: 900;
}
p,
.font-style {
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
}
.capitular:first-letter {
  font-size: 30px;
  float: left;
  margin-right: 5px;
  font-weight: bold;
}
.text-a,
.text-b {
  font-size: 20px;
}
@media (max-width: 768px) {
  .text-a,
  .text-b {
    font-size: 15px;
  }
  p {
    text-align: center;
  }
}
h1 {
  font-size: 5rem;
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 4.5rem;
    text-align: left;
  }
  #banner .banner-home{
    background-position: -200px center !important;
  }
  body.devir .logo-skin {
    top: -60px !important;
  }
}
@media screen and (max-width: 576px) {
  h1 {
    font-size: 4rem;
    text-align: center;
  }
}
h2 {
  font-size: 4rem;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 3.6rem;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  h2 {
    font-size: 3.2rem;
    text-align: center;
  }
}
h3 {
  font-size: 3.5rem;
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 3.15rem;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  h3 {
    font-size: 2.8rem;
    text-align: center;
  }
}
h4 {
  font-size: 3rem;
}
@media screen and (max-width: 768px) {
  h4 {
    font-size: 2.7rem;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  h4 {
    font-size: 2.4rem;
    text-align: center;
  }
}
h5 {
  font-size: 2.5rem;
}
@media screen and (max-width: 768px) {
  h5 {
    font-size: 2.25rem;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  h5 {
    font-size: 2rem;
    text-align: center;
  }
}
h6 {
  font-size: 2rem;
}
@media screen and (max-width: 768px) {
  h6 {
    font-size: 1.8rem;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  h6 {
    font-size: 1.6rem;
    text-align: center;
  }
}
.font-extra-small {
  font-size: 9px;
}
.font-small {
  font-size: 12px;
}
.font-medium {
  font-size: 15px;
}
.font-large {
  font-size: 18px;
}
.font-extra-large {
  font-size: 22px;
}
body {
  font: 100%;
  font-family: 'Flexo-Regular', sans-serif !important;
  background-color: #ededed;
}
body.alumiar {
  overflow-x: hidden;
}
p {
  font-size: 15px;
}
h1 {
  font-weight: lighter;
}
.ul-margin-10 {
  padding-left: 15px;
}
blockquote {
  border-left: 5px solid #211915;
}
hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #d6d6d6;
}
.pager li > a,
.pager li > span {
  border-radius: 0;
}
::-webkit-scrollbar {
  width: 0.5em;
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #f25d21;
  outline: 1px solid #708090;
  border-radius: 5px;
}
.has-error .form-control {
  border: 2px solid #d20505;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}
.btn {
  border-radius: 0;
  color: #fff;
  background-color: #363d69;
  box-shadow: 2px 2px 4px #a9a9a9;
  transition: color 1s, box-shadow 1s;
}
.btn:hover {
  background-color: #363d69;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
.btn:active {
  background-color: #0176ec;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
.btn:focus {
  background-color: #0176ec;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
.btn-default[disabled]:hover {
  background-color: #3498fe;
}
.btn-pesquisa {
  padding: 10px 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.btn-redondo {
  border-radius: 10px;
}
.btn-convidado {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.btn-open-pesquisa {
  margin-top: 20px;
}
@media (max-width: 576px) {
  .btn-open-pesquisa:not(:first-child) {
    margin-top: 10px;
  }
}
.pd-20 {
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .pd-20 {
    padding: 10px;
  }
}
@media screen and (max-width: 576px) {
  .pd-20 {
    padding: 6px;
  }
}
.pd-30 {
  padding: 30px;
}
@media screen and (max-width: 768px) {
  .pd-30 {
    padding: 15px;
  }
}
@media screen and (max-width: 576px) {
  .pd-30 {
    padding: 10px;
  }
}
.pd-40 {
  padding: 40px;
}
@media screen and (max-width: 768px) {
  .pd-40 {
    padding: 20px;
  }
}
@media screen and (max-width: 576px) {
  .pd-40 {
    padding: 13px;
  }
}
.pd-60 {
  padding: 60px;
}
@media screen and (max-width: 768px) {
  .pd-60 {
    padding: 30px;
  }
}
@media screen and (max-width: 576px) {
  .pd-60 {
    padding: 20px;
  }
}
.pd-80 {
  padding: 80px;
}
@media screen and (max-width: 768px) {
  .pd-80 {
    padding: 40px;
  }
}
@media screen and (max-width: 576px) {
  .pd-80 {
    padding: 26px;
  }
}
.pd-20-top {
  padding-top: 20px;
}
@media screen and (max-width: 768px) {
  .pd-20-top {
    padding-top: 10px;
  }
}
@media screen and (max-width: 576px) {
  .pd-20-top {
    padding-top: 6px;
  }
}
.pd-30-top {
  padding-top: 30px;
}
@media screen and (max-width: 768px) {
  .pd-30-top {
    padding-top: 15px;
  }
}
@media screen and (max-width: 576px) {
  .pd-30-top {
    padding-top: 10px;
  }
}
.pd-40-top {
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .pd-40-top {
    padding-top: 20px;
  }
}
@media screen and (max-width: 576px) {
  .pd-40-top {
    padding-top: 13px;
  }
}
.pd-60-top {
  padding-top: 60px;
}
@media screen and (max-width: 768px) {
  .pd-60-top {
    padding-top: 30px;
  }
}
@media screen and (max-width: 576px) {
  .pd-60-top {
    padding-top: 20px;
  }
}
.pd-80-top {
  padding-top: 80px;
}
@media screen and (max-width: 768px) {
  .pd-80-top {
    padding-top: 40px;
  }
}
@media screen and (max-width: 576px) {
  .pd-80-top {
    padding-top: 26px;
  }
}
.pd-20-bottom {
  padding-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .pd-20-bottom {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 576px) {
  .pd-20-bottom {
    padding-bottom: 6px;
  }
}
.pd-30-bottom {
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .pd-30-bottom {
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 576px) {
  .pd-30-bottom {
    padding-bottom: 10px;
  }
}
.pd-40-bottom {
  padding-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .pd-40-bottom {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 576px) {
  .pd-40-bottom {
    padding-bottom: 13px;
  }
}
.pd-60-bottom {
  padding-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .pd-60-bottom {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 576px) {
  .pd-60-bottom {
    padding-bottom: 20px;
  }
}
.pd-80-bottom {
  padding-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .pd-80-bottom {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 576px) {
  .pd-80-bottom {
    padding-bottom: 26px;
  }
}
.pd-20-right {
  padding-right: 20px;
}
@media screen and (max-width: 768px) {
  .pd-20-right {
    padding-right: 10px;
  }
}
@media screen and (max-width: 576px) {
  .pd-20-right {
    padding-right: 6px;
  }
}
.pd-30-right {
  padding-right: 30px;
}
@media screen and (max-width: 768px) {
  .pd-30-right {
    padding-right: 15px;
  }
}
@media screen and (max-width: 576px) {
  .pd-30-right {
    padding-right: 10px;
  }
}
.pd-40-right {
  padding-right: 40px;
}
@media screen and (max-width: 768px) {
  .pd-40-right {
    padding-right: 20px;
  }
}
@media screen and (max-width: 576px) {
  .pd-40-right {
    padding-right: 13px;
  }
}
.pd-60-right {
  padding-right: 60px;
}
@media screen and (max-width: 768px) {
  .pd-60-right {
    padding-right: 30px;
  }
}
@media screen and (max-width: 576px) {
  .pd-60-right {
    padding-right: 20px;
  }
}
.pd-80-right {
  padding-right: 80px;
}
@media screen and (max-width: 768px) {
  .pd-80-right {
    padding-right: 40px;
  }
}
@media screen and (max-width: 576px) {
  .pd-80-right {
    padding-right: 26px;
  }
}
.pd-20-left {
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .pd-20-left {
    padding-left: 10px;
  }
}
@media screen and (max-width: 576px) {
  .pd-20-left {
    padding-left: 6px;
  }
}
.pd-30-left {
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  .pd-30-left {
    padding-left: 15px;
  }
}
@media screen and (max-width: 576px) {
  .pd-30-left {
    padding-left: 10px;
  }
}
.pd-40-left {
  padding-left: 40px;
}
@media screen and (max-width: 768px) {
  .pd-40-left {
    padding-left: 20px;
  }
}
@media screen and (max-width: 576px) {
  .pd-40-left {
    padding-left: 13px;
  }
}
.pd-60-left {
  padding-left: 60px;
}
@media screen and (max-width: 768px) {
  .pd-60-left {
    padding-left: 30px;
  }
}
@media screen and (max-width: 576px) {
  .pd-60-left {
    padding-left: 20px;
  }
}
.pd-80-left {
  padding-left: 80px;
}
@media screen and (max-width: 768px) {
  .pd-80-left {
    padding-left: 40px;
  }
}
@media screen and (max-width: 576px) {
  .pd-80-left {
    padding-left: 26px;
  }
}
.mg-20 {
  margin: 20px;
}
@media screen and (max-width: 728px) {
  .mg-20 {
    margin: 10px;
  }
}
.mg-30 {
  margin: 30px;
}
@media screen and (max-width: 728px) {
  .mg-30 {
    margin: 15px;
  }
}
.mg-40 {
  margin: 40px;
}
@media screen and (max-width: 728px) {
  .mg-40 {
    margin: 20px;
  }
}
.mg-60 {
  margin: 60px;
}
@media screen and (max-width: 728px) {
  .mg-60 {
    margin: 30px;
  }
}
.mg-80 {
  margin: 80px;
}
@media screen and (max-width: 728px) {
  .mg-80 {
    margin: 40px;
  }
}
.mg-20-top {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .mg-20-top {
    margin-top: 10px;
  }
}
@media screen and (max-width: 576px) {
  .mg-20-top {
    margin-top: 6px;
  }
}
.mg-30-top {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .mg-30-top {
    margin-top: 15px;
  }
}
@media screen and (max-width: 576px) {
  .mg-30-top {
    margin-top: 10px;
  }
}
.mg-40-top {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .mg-40-top {
    margin-top: 20px;
  }
}
@media screen and (max-width: 576px) {
  .mg-40-top {
    margin-top: 13px;
  }
}
.mg-60-top {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .mg-60-top {
    margin-top: 30px;
  }
}
@media screen and (max-width: 576px) {
  .mg-60-top {
    margin-top: 20px;
  }
}


.mg-20-bottom {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .mg-20-bottom {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 576px) {
  .mg-20-bottom {
    margin-bottom: 6px;
  }
}

.loader-login{
  max-width: 30px;
  animation: rotate 1.5s infinite ease;
}

.mg-30-bottom {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .mg-30-bottom {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 576px) {
  .mg-30-bottom {
    margin-bottom: 10px;
  }
}
.mg-40-bottom {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .mg-40-bottom {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 576px) {
  .mg-40-bottom {
    margin-bottom: 13px;
  }
}
.mg-60-bottom {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .mg-60-bottom {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 576px) {
  .mg-60-bottom {
    margin-bottom: 20px;
  }
}
.mg-80-bottom {
  margin-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .mg-80-bottom {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 576px) {
  .mg-80-bottom {
    margin-bottom: 26px;
  }
}
.mg-20-right {
  margin-right: 20px;
}
@media screen and (max-width: 768px) {
  .mg-20-right {
    margin-right: 10px;
  }
}
@media screen and (max-width: 576px) {
  .mg-20-right {
    margin-right: 6px;
  }
}
.mg-30-right {
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .mg-30-right {
    margin-right: 15px;
  }
}
@media screen and (max-width: 576px) {
  .mg-30-right {
    margin-right: 10px;
  }
}
.mg-40-right {
  margin-right: 40px;
}
@media screen and (max-width: 768px) {
  .mg-40-right {
    margin-right: 20px;
  }
}
@media screen and (max-width: 576px) {
  .mg-40-right {
    margin-right: 13px;
  }
}
.mg-60-right {
  margin-right: 60px;
}
@media screen and (max-width: 768px) {
  .mg-60-right {
    margin-right: 30px;
  }
}
@media screen and (max-width: 576px) {
  .mg-60-right {
    margin-right: 20px;
  }
}
.mg-80-right {
  margin-right: 80px;
}
@media screen and (max-width: 768px) {
  .mg-80-right {
    margin-right: 40px;
  }
}
@media screen and (max-width: 576px) {
  .mg-80-right {
    margin-right: 26px;
  }
}
.mg-20-left {
  margin-left: 20px;
}
@media screen and (max-width: 768px) {
  .mg-20-left {
    margin-left: 10px;
  }
}
@media screen and (max-width: 576px) {
  .mg-20-left {
    margin-left: 6px;
  }
}
.mg-30-left {
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .mg-30-left {
    margin-left: 15px;
  }
}
@media screen and (max-width: 576px) {
  .mg-30-left {
    margin-left: 10px;
  }
}
.mg-40-left {
  margin-left: 40px;
}
@media screen and (max-width: 768px) {
  .mg-40-left {
    margin-left: 20px;
  }
}
@media screen and (max-width: 576px) {
  .mg-40-left {
    margin-left: 13px;
  }
}
.mg-60-left {
  margin-left: 60px;
}
@media screen and (max-width: 768px) {
  .mg-60-left {
    margin-left: 30px;
  }
}
@media screen and (max-width: 576px) {
  .mg-60-left {
    margin-left: 20px;
  }
}
.mg-80-left {
  margin-left: 80px;
}
@media screen and (max-width: 768px) {
  .mg-80-left {
    margin-left: 40px;
  }
}
@media screen and (max-width: 576px) {
  .mg-80-left {
    margin-left: 26px;
  }
}
header .suporte-top {
}
header .suporte-top .logo {
  margin: auto;
}
.logo {
  max-height: 80px;
}
#convidados h3 {
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}
#convidados btn {
  border-radius: 20px;
}
.main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 320px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 0 10px;
}
.main-menu li {
  text-decoration: none;
  list-style: none;
}
.main-menu li a {
  color: #099742;
  text-transform: uppercase;
  font-weight: 900;
  text-decoration: none;
}
.headerGeral{
  position: absolute;
  display: flex;
  justify-content: flex-start;
  height: 100vh;
}
@media screen and (max-width: 320px){
  .login{
    margin-bottom: 50px;
  }
  #banner .banner-home {
    background-position: -125px center !important;
  }
  .links-home ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    position: absolute;
    top: 15%!important;
    right: 25%!important;
  }
  body.devir .logo-skin {
    background-image: url(/images/devir.svg);
    width: 150px !important;
    height: 200px !important;
    background-size: contain;
    position: inherit!important;
    margin: auto!important;
  }
}

@media (max-width: 768px) {
  header .suporte-top {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .main-menu {
    padding: 0 20px;
    margin-top: 20px;
  }
  .logo {
    max-height: 50px;
  }
  #convidados h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }
}
.anima-up {
  -webkit-animation: slide 2s infinite;
          animation: slide 2s infinite;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}
.rebolar {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}
@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}
@-webkit-keyframes slide {
  0% {
    opacity: 0;
    top: 10px;
  }
  50% {
    opacity: 1;
    top: 5px;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    top: 10px;
  }
  50% {
    opacity: 1;
    top: 5px;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}
.bg-default {
  background-color: #211915;
}
.bg-inverse {
  background-color: #fff;
}
.bg-second {
  background-color: #f25d21;
}
.bg-clear {
  background-color: #ededed;
}
.bg-main {
  background-color: #0154a8;
}
.text-default {
  color: #211915;
}
.text-inverse {
  color: #fff;
}
.text-second {
  color: #f25d21;
}
.text-clear {
  color: #ededed;
}
.text-main {
  color: #363d69;
}
.rodape img {
  opacity: 0.3;
}
body.meta-gestao::-webkit-scrollbar-thumb {
  background-color: #b00069;
}
body.meta-gestao .header-color,
body.meta-gestao .pager li > a,
body.meta-gestao .pager li > span,
body.meta-gestao footer,
body.meta-gestao .btn {
  background-color: #b00069;
  color: #fff;
}
body.meta-gestao .header-color:active,
body.meta-gestao .pager li > a:active,
body.meta-gestao .pager li > span:active,
body.meta-gestao footer:active,
body.meta-gestao .btn:active {
  background-color: #d1007d;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.meta-gestao .header-color:focus,
body.meta-gestao .pager li > a:focus,
body.meta-gestao .pager li > span:focus,
body.meta-gestao footer:focus,
body.meta-gestao .btn:focus {
  background-color: #d1007d;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.meta-gestao .wrraper-pergunta {
  border: 5px solid #fff8fc;
}
body.meta-gestao .btn-topo {
  background-color: rgba(176,0,105,0.6);
}
body.meta-gestao .logo-skin {
  background-image: url("/images/logos/meta-gestao.svg");
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
body.meta-gestao .logo-intranet {
  background-image: url("/images/logos/meta-gestao.svg");
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px !important;
}
body.meta-gestao .avatar {
  background-image: url("/images/logos-coloridos/meta-gestao.svg");
}
body.meta-gestao .btn-success {
  background-color: #398339;
}
body.meta-gestao .btn-success:active {
  background-color: #398339;
}
body.meta-gestao .btn-success:focus {
  background-color: #398339;
}
body.meta-gestao .btn-danger {
  background-color: #d20505;
}
body.meta-gestao .btn-danger:active {
  background-color: #d20505;
}
body.meta-gestao .btn-danger:focus {
  background-color: #d20505;
}
body.meta-gestao .main-menu li a {
  color: #b00069;
}
body.meta-gestao .valorInput.ativo {
  background-color: #b00069;
}
body.meta-gestao .bg-atual {
  background-color: #b00069;
}
body.meta-gestao .text-atual {
  color: #b00069;
}
body.alumiar::-webkit-scrollbar-thumb {
  background-color: #ea5b0c;
}
body.alumiar .header-color,
body.alumiar .pager li > a,
body.alumiar .pager li > span,
body.alumiar footer,
body.alumiar .btn {
  background-color: #ea5b0c;
  color: #fff;
}
body.alumiar .header-color:active,
body.alumiar .pager li > a:active,
body.alumiar .pager li > span:active,
body.alumiar footer:active,
body.alumiar .btn:active {
  background-color: #f3691d;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.alumiar .header-color:focus,
body.alumiar .pager li > a:focus,
body.alumiar .pager li > span:focus,
body.alumiar footer:focus,
body.alumiar .btn:focus {
  background-color: #f3691d;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.alumiar .wrraper-pergunta {
  border: 5px solid #fffcfa;
}
body.alumiar .btn-topo {
  background-color: rgba(234,91,12,0.6);
}
body.alumiar .logo-skin {
  background-image: url("/images/logos/alumiar.svg");
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
body.alumiar .logo-intranet {
  background-image: url("/images/logos/alumiar.svg");
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px !important;
}

.logo-skin{
  position: relative;
  z-index: 9;
}

.alturaPagGeral{
    height: 450px;
    overflow: auto;
    border: 1px solid #ededed;
    border-top: 0px solid #ededed;
}

.filter{
  background-color: rgba(1,84,166,.6);
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

body.alumiar .avatar {
  background-image: url("/images/logos-coloridos/alumiar-colorido.svg");
}
body.alumiar .btn-success {
  background-color: #398339;
}
body.alumiar .btn-success:active {
  background-color: #398339;
}
body.alumiar .btn-success:focus {
  background-color: #398339;
}
body.alumiar .btn-danger {
  background-color: #d20505;
}
body.alumiar .btn-danger:active {
  background-color: #d20505;
}
body.alumiar .btn-danger:focus {
  background-color: #d20505;
}
body.alumiar .main-menu li a {
  color: #ea5b0c;
}
body.alumiar .valorInput.ativo {
  background-color: #ea5b0c;
}
body.alumiar .bg-atual {
  background-color: #ea5b0c;
}
body.alumiar .text-atual {
  color: #ea5b0c;
}
body.meta-riqueza::-webkit-scrollbar-thumb {
  background-color: #fdb812;
}
body.meta-riqueza .header-color,
body.meta-riqueza .pager li > a,
body.meta-riqueza .pager li > span,
body.meta-riqueza footer,
body.meta-riqueza .btn {
  background-color: #fdb812;
  color: #fff;
}
body.meta-riqueza .header-color:active,
body.meta-riqueza .pager li > a:active,
body.meta-riqueza .pager li > span:active,
body.meta-riqueza footer:active,
body.meta-riqueza .btn:active {
  background-color: #fdbf2a;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.meta-riqueza .header-color:focus,
body.meta-riqueza .pager li > a:focus,
body.meta-riqueza .pager li > span:focus,
body.meta-riqueza footer:focus,
body.meta-riqueza .btn:focus {
  background-color: #fdbf2a;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.meta-riqueza .wrraper-pergunta {
  border: 5px solid #fffefa;
}
body.meta-riqueza .btn-topo {
  background-color: rgba(253,184,18,0.6);
}
body.meta-riqueza .logo-skin {
  background-image: url("/images/logos/meta-riqueza.svg");
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
body.meta-riqueza .logo-intranet {
  background-image: url("/images/logos/meta-riqueza.svg");
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px !important;
}
body.meta-riqueza .avatar {
  background-image: url("/images/logos-coloridos/meta-riqueza.svg");
}
body.meta-riqueza .btn-success {
  background-color: #398339;
}
body.meta-riqueza .btn-success:active {
  background-color: #398339;
}
body.meta-riqueza .btn-success:focus {
  background-color: #398339;
}
body.meta-riqueza .btn-danger {
  background-color: #d20505;
}
body.meta-riqueza .btn-danger:active {
  background-color: #d20505;
}
body.meta-riqueza .btn-danger:focus {
  background-color: #d20505;
}
body.meta-riqueza .main-menu li a {
  color: #fdb812;
}
body.meta-riqueza .valorInput.ativo {
  background-color: #fdb812;
}
body.meta-riqueza .bg-atual {
  background-color: #fdb812;
}
body.meta-riqueza .text-atual {
  color: #fdb812;
}
body.limiar::-webkit-scrollbar-thumb {
  background-color: #099742;
}
body.limiar .header-color,
body.limiar .pager li > a,
body.limiar .pager li > span,
body.limiar footer,
body.limiar .btn {
  background-color: #099742;
  color: #fff;
}
body.limiar .header-color:active,
body.limiar .pager li > a:active,
body.limiar .pager li > span:active,
body.limiar footer:active,
body.limiar .btn:active {
  background-color: #0bb850;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.limiar .header-color:focus,
body.limiar .pager li > a:focus,
body.limiar .pager li > span:focus,
body.limiar footer:focus,
body.limiar .btn:focus {
  background-color: #0bb850;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.limiar .wrraper-pergunta {
  border: 5px solid #f8fffb;
}
body.limiar .btn-topo {
  background-color: rgba(9,151,66,0.6);
}
body.limiar .logo-skin {
  background-image: url("/images/logos/limiar.svg");
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
body.limiar .logo-intranet {
  background-image: url("/images/logos/limiar.svg");
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px !important;
}
body.limiar .avatar {
  background-image: url("/images/logos-coloridos/limiar.svg");
}
body.limiar .btn-success {
  background-color: #398339;
}
body.limiar .btn-success:active {
  background-color: #398339;
}
body.limiar .btn-success:focus {
  background-color: #398339;
}
body.limiar .btn-danger {
  background-color: #d20505;
}
body.limiar .btn-danger:active {
  background-color: #d20505;
}
body.limiar .btn-danger:focus {
  background-color: #d20505;
}
body.limiar .main-menu li a {
  color: #099742;
}
body.limiar .valorInput.ativo {
  background-color: #099742;
}
body.limiar .bg-atual {
  background-color: #099742;
}
body.limiar .text-atual {
  color: #099742;
}
body.academia::-webkit-scrollbar-thumb {
  background-color: #232d4b;
}
body.academia .header-color,
body.academia .pager li > a,
body.academia .pager li > span,
body.academia footer,
body.academia .btn {
  background-color: #232d4b;
  color: #fff;
}
body.academia .header-color:active,
body.academia .pager li > a:active,
body.academia .pager li > span:active,
body.academia footer:active,
body.academia .btn:active {
  background-color: #303d66;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.academia .header-color:focus,
body.academia .pager li > a:focus,
body.academia .pager li > span:focus,
body.academia footer:focus,
body.academia .btn:focus {
  background-color: #303d66;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.academia .wrraper-pergunta {
  border: 5px solid #fafafc;
}
body.academia .btn-topo {
  background-color: rgba(35,45,75,0.6);
}
body.academia .logo-skin {
  background-image: url("/images/logos-coloridos/academia.svg");
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
body.academia .logo-intranet {
  background-image: url("/images/logos-coloridos/academia.svg");
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px !important;
}
body.academia .avatar {
  background-image: url("/images/logos-coloridos/academia.svg");
}
body.academia .btn-success {
  background-color: #398339;
}
body.academia .btn-success:active {
  background-color: #398339;
}
body.academia .btn-success:focus {
  background-color: #398339;
}
body.academia .btn-danger {
  background-color: #d20505;
}
body.academia .btn-danger:active {
  background-color: #d20505;
}
body.academia .btn-danger:focus {
  background-color: #d20505;
}
body.academia .main-menu li a {
  color: #232d4b;
}
body.academia .valorInput.ativo {
  background-color: #232d4b;
}
body.academia .bg-atual {
  background-color: #232d4b;
}
body.academia .text-atual {
  color: #232d4b;
}
body.paidea::-webkit-scrollbar-thumb {
  background-color: #b00069;
}
body.paidea .header-color,
body.paidea .pager li > a,
body.paidea .pager li > span,
body.paidea footer,
body.paidea .btn {
  background-color: #b00069;
  color: #fff;
}
body.paidea .header-color:active,
body.paidea .pager li > a:active,
body.paidea .pager li > span:active,
body.paidea footer:active,
body.paidea .btn:active {
  background-color: #d1007d;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.paidea .header-color:focus,
body.paidea .pager li > a:focus,
body.paidea .pager li > span:focus,
body.paidea footer:focus,
body.paidea .btn:focus {
  background-color: #d1007d;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.paidea .wrraper-pergunta {
  border: 5px solid #fff8fc;
}
body.paidea .btn-topo {
  background-color: rgba(176,0,105,0.6);
}
body.paidea .logo-skin {
  background-image: url("/images/logos-coloridos/meta-gestao.svg");
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
body.paidea .logo-intranet {
  background-image: url("/images/logos-coloridos/meta-gestao.svg");
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px !important;
}
body.paidea .avatar {
  background-image: url("/images/logos-coloridos/meta-gestao.svg");
}
body.paidea .btn-success {
  background-color: #398339;
}
body.paidea .btn-success:active {
  background-color: #398339;
}
body.paidea .btn-success:focus {
  background-color: #398339;
}
body.paidea .btn-danger {
  background-color: #d20505;
}
body.paidea .btn-danger:active {
  background-color: #d20505;
}
body.paidea .btn-danger:focus {
  background-color: #d20505;
}
body.paidea .main-menu li a {
  color: #b00069;
}
body.paidea .valorInput.ativo {
  background-color: #b00069;
}
body.paidea .bg-atual {
  background-color: #b00069;
}
body.paidea .text-atual {
  color: #b00069;
}
body.metanoia::-webkit-scrollbar-thumb {
  background-color: #099742;
}
body.metanoia .header-color,
body.metanoia .pager li > a,
body.metanoia .pager li > span,
body.metanoia footer,
body.metanoia .btn {
  background-color: #099742;
  color: #fff;
}
body.metanoia .header-color:active,
body.metanoia .pager li > a:active,
body.metanoia .pager li > span:active,
body.metanoia footer:active,
body.metanoia .btn:active {
  background-color: #0bb850;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.metanoia .header-color:focus,
body.metanoia .pager li > a:focus,
body.metanoia .pager li > span:focus,
body.metanoia footer:focus,
body.metanoia .btn:focus {
  background-color: #0bb850;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.metanoia .wrraper-pergunta {
  border: 5px solid #f8fffb;
}
body.metanoia .btn-topo {
  background-color: rgba(9,151,66,0.6);
}
body.metanoia .logo-skin {
  background-image: url("/images/logos-coloridos/metanoia.svg");
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
body.metanoia .logo-intranet {
  background-image: url("/images/logos-coloridos/metanoia.svg");
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100px !important;
}
body.metanoia .avatar {
  background-image: url("/images/logos-coloridos/metanoia.svg");
}
body.metanoia .btn-success {
  background-color: #398339;
}
body.metanoia .btn-success:active {
  background-color: #398339;
}
body.metanoia .btn-success:focus {
  background-color: #398339;
}
body.metanoia .btn-danger {
  background-color: #d20505;
}
body.metanoia .btn-danger:active {
  background-color: #d20505;
}
body.metanoia .btn-danger:focus {
  background-color: #d20505;
}
body.metanoia .main-menu li a {
  color: #099742;
}
body.metanoia .valorInput.ativo {
  background-color: #099742;
}
body.metanoia .bg-atual {
  background-color: #099742;
}
body.metanoia .text-atual {
  color: #099742;
}
body.devir::-webkit-scrollbar-thumb {
  background-color: #363d69;
}
body.devir .header-color,
body.devir .pager li > a,
body.devir .pager li > span,
body.devir footer,
body.devir .btn {
  color: #fff;
}
body.devir .header-color:active,
body.devir .pager li > a:active,
body.devir .pager li > span:active,
body.devir .btn:active {
  background-color: #0165c8;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.devir .header-color:focus,
body.devir .pager li > a:focus,
body.devir .pager li > span:focus,
body.devir footer:focus,
body.devir .btn:focus {
  background-color: #0165c8;
  color: #fff;
  box-shadow: 2px 0px 8px #9e9c9c;
}
body.devir .wrraper-pergunta {
  border: 5px solid #f8fcff;
}
body.devir .btn-topo {
  background-color: rgba(1,84,166,0.6);
}
body.devir .logo-skin {
  background-image: url('/images/devir.svg');
  width: 150px !important;
  height: 200px !important;
  background-size: contain;
  position: absolute;
  top: -80px;
  left: 5%;
}
body.devir .logo-intranet {
  background-image: url("/images/logos-coloridos/incandessencia.png");
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100px !important;
}
body.devir .avatar {
  background-image: url("/images/logos-coloridos/incandessencia.png");
}
body.devir .btn-success {
  background-color: #398339;
}
body.devir .btn-success:active {
  background-color: #398339;
}
body.devir .btn-success:focus {
  background-color: #398339;
}
body.devir .btn-danger {
  background-color: #d20505;
}
body.devir .btn-danger:active {
  background-color: #d20505;
}
body.devir .btn-danger:focus {
  background-color: #d20505;
}
body.devir .main-menu li a {
  color: #363d69;
}
body.devir .valorInput.ativo {
  background-color: #363d69;
}
body.devir .bg-atual {
  background-color: #363d69;
}
body.devir .text-atual {
  color: #363d69;
}
.pes{
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  background-color: #2df8ff;
  border-radius: 50px;
  top: 10px;
  filter: blur(2px);
  opacity: 0.8;
}

.progress-bar{
  position: relative;
}
.progress{
  height: 28px;
}

@media print {
  header,
  .navegacao,
  footer {
    display: none;
  }
  #titulo {
    text-align: center;
  }
  .printPage {
    page-break-before: always;
  }
  .pd-40-top {
    padding-top: 5px;
  }
  #mainChart {
    height: 220px;
  }
}

.boxDevir{
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 1px 6px 1px #46464666;
  border-radius: 4px;

}

#banner .banner-home {
  width: 100%;
  height: 100vh;
  padding-left: 0;
  padding-right: 0;
  background-image: url('/images/Painel-da-Constelacao-2.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

#banner .banner-home small{
  color: #fff;
  padding: 20px;
  font-size: 20px;
}

#banner .titulo-banner {
  color: #fff;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, #000 100%);
  padding: 20px;
  padding-left: 35px;
  text-shadow: 2px 2px 5px #090909;
  text-transform: uppercase;
}
#banner .titulo-banner h2 {
  margin-bottom: 3px;
}
#banner .titulo-banner span {
  font-size: 20px;
  text-transform: capitalize;
}
#banner .titulo-banner a {
  color: #f25d21;
  text-transform: none;
}

.links-home ul{
  list-style: none;
  padding-left: 0;
  display: flex;
  position: absolute;
  top: 30px;
  right: 5%;
}

.links-home ul li{
  margin-right: 15px;
  font-size: 16px;
}

.links-home ul li a{
  color: #fff;
}

.box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.box .box-home {
  background-color: #fff;
  padding: 10px;
  box-shadow: 1px 1px 2px #808080;
  width: 95%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.box .box-home .box-header {
  border: 1px solid #ededed;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 150px;
  overflow: hidden;
}
.box .box-home .box-header .artigos-home .bg-artigos {
  height: 90px;
}
.box .box-home .box-content {
  height: 350px;
  overflow-y: scroll;
  border: 1px solid #ededed;
  border-top: 0px solid #ededed;
}
.box .box-home .box-content .artigos-home{ 
  border-bottom: 1px solid #ededed;
}
.box .box-home .box-header .artigos-home{
  border-bottom: 0px solid #ededed;
}
.box .box-home .box-footer {
  border: 1px solid #ededed;
  padding: 10px 0;
}
.box .box-home .podcasts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.box .box-home .podcasts p {
  font-size: 14px;
}
.box .box-home .podcasts img {
  width: 55px;
}
.box .box-home .inscricao .btn-inscricao1 {
  background-image: url("/images/icone-castbox.png");
  background-color: transparent;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  box-shadow: 0px 0px 0px #a9a9a9;
}
.box .box-home .inscricao .btn-inscricao2 {
  background-image: url("/images/icone-spotify.png");
  background-color: transparent;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  box-shadow: 0px 0px 0px #a9a9a9;
  display: none;
}
.box .box-home .inscricao .btn-inscricao3 {
  background-image: url("/images/icone-itunes.png");
  background-color: transparent;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  box-shadow: 0px 0px 0px #a9a9a9;
}
.box .box-home .inscricao > * {
  margin-left: 10px;
}
.box .box-home .inscricao{
  display: flex;
}
.box .box-home .box-content .artigos-home .bg-artigos{
  padding-bottom: 56%;
}
@media screen and (max-width: 414px){
  .box .box-home .box-content .artigos-home .bg-artigos{
    padding-bottom: 100%;
  }
  #banner .banner-home{
    background-position: -150px center !important;
  }
  .links-home ul li{
    margin-right: 5px;
    font-size: 14px;
  }
}

@media screen and (max-width: 420px){
  .box .box-home .inscricao .btn-inscricao2{
    display: flex;
  }
}

.box .box-home .artigos-home {
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
}
.box .box-home .artigos-home:first-child {
  margin-top: 0;
}
.box .box-home .artigos-home a {
  font-weight: bold;
}
.box .box-home .artigos-home .bg-artigos {
  background-image: url("/images/tornar-se-humano-roberto-tranjan.jpg");
  width: 100%;
  height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #8e8d8d;
  margin-bottom: 20px;
  padding-bottom: 100%
}
.box .box-home p {
  margin-bottom: 5px;
}
.box .box-home .geral,
.box .box-home .aniversario,
.box .box-home .noticias {
  border-bottom: 1px solid #d3d3d3;
  padding: 10px 0;
}
.box .box-home .geral:last-child,
.box .box-home .aniversario:last-child,
.box .box-home .noticias:last-child {
  border-bottom: 0 solid #d3d3d3;
}
.box .box-home .geral .text-geral {
  text-align: left;
}
.box .box-home .geral p {
  text-align: left;
}
.box .box-home .noticias {
  padding: 20px;
}
.box .box-home .aniversario {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.box .box-home .aniversario .aniversariantes {
  background-image: url("/images/silvio.png");
  width: 85px;
  height: 85px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  box-shadow: 1px 1px 5px #373737;
}
.box .box-home .aniversario .data-aniver {
  font-size: 11px;
  text-align: left;
}
.box .box-home .aniversario .nome-niver {
  color: #f25d21;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}
.box .box-home .aniversario .link-niver {
  color: #f25d21;
  text-decoration: underline;
  font-weight: bold;
  text-align: left;
}
.box .box-home .aniversario .fone-niver {
  font-size: 14px;
  text-align: left;
}
.box .box-home .aniversario .fone-niver img {
  width: 15px;
  margin-right: 10px;
}
.box .box-home .aniversario p {
  margin-bottom: 0;
}
.box .aniver,
.box .lume {
  color: #f25d21;
}
.titulo-box {
  font-weight: bold;
}
.fotos > * {
  padding-left: 0;
  padding-right: 0;
}
footer {
  padding-top: 10px;
  padding-bottom: 10px;
}
.todos-artigos {
  padding-left: 0;
  padding-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.todos-podcasts {
  padding-left: 0;
  padding-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.loader {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px
}
.loader .loaderGlobal {
  position: fixed;
  width: 50px;
  height: 50px;
  z-index: 9999;
  background: #fff url("/images/load.gif") no-repeat center center;
  background-size: contain;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
}


.center-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.galeria-modal {
  position: relative;
  background-color: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 80vh;
  width: 95%;
  margin-bottom: 20px;
}

.btnClickArtigo{
  background-color: transparent;
  border: none;
  padding-left: 0;
  font-weight: bolder
}

.galeria-modal .loader-galeria {
  position: fixed;
  width: 50px;
  height: 50px;
  z-index: 9999;
  background: #fff url("/images/load.gif") no-repeat center center;
  background-size: 90px;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
}
.galeria-modal img {
  border: 3px solid #f25d21;
  box-shadow: 2px 3px 3px #081e34;
}
.galeria-modal .fechar {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 2;
  color: #fff;
  font-size: 32px;
}
.box .box-home .artigos-home .titulo-art {
  margin-block-start: 0;
}
@media screen and (max-width: 991px) {
  .todos-artigos,
  .todos-podcasts {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .box .titulo-box,
  .box .titulo-artigo {
    margin-left: 0;
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
  .box .box-home {
    width: 100%;
  }
  .box .box-home .artigos-home:first-child {
    margin-top: 15px;
  }
  .box .box-home .artigos-home .titulo-art {
    text-align: left;
    margin-block-start: 0;
  }
  .box .box-home .artigos-home p {
    text-align: left;
  }
  .box .box-home .podcasts .titulo-podcasts {
    text-align: left;
  }
  .box .box-home .podcasts p {
    text-align: left;
  }
  .box .aniver {
    margin-left: 0 !important;
  }
  .box .box-home .artigos-home .bg-artigos{
    padding-bottom: 50%;
  }
}
@media screen and (max-width: 767px) {
  .box .box-home {
    width: 98%;
  }
  .box .titulo-box,
  .box .titulo-artigo {
    margin-left: 10px;
  }
  .box .aniver {
    margin-left: 10px !important;
  }
  .fotos img {
    margin-top: 15px;
  }
  .login-wrraper {
    min-height: calc(100vh - 150px);
  }
  .logos{
    justify-content: space-between;
  }
  body.devir .logo-skin {
    top: -70px !important;
    left: 50%;
  }
}
@media screen and (max-width: 576px) {
  #banner .titulo-banner {
    text-align: center;
  }
}
.login-wrraper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  align-items: center;
}
.login {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  max-width: 340px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  box-shadow: 1px 1px 3px #7d7d7d;
}
.logo-obra {
  margin-top: 20px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  width: 100px;
  height: 80px;
  background-size: contain;
  background-position: center center;
}


.noticia {
  padding-left: 0;
  padding-right: 0;
}
.italic {
  font-style: italic;
}
.hr-niver {
  margin-bottom: 5px;
  margin-top: 5px;
}
.bg-lume {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  transition: 1s;
}
.bg-lume .resultado-lume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  transition: 1.5s;
  overflow: hidden;
}
.box-home .resultado-lume .basilar {
  background-color: rgba(242,93,33,0.9);
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  opacity: 0;
  transition: 1.5s;
  cursor: pointer;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bg-lume:hover .basilar {
  -webkit-transform: translate(0);
          transform: translate(0);
  opacity: 1;
}
.bg-lume:hover .basilar a {
  color: #fff;
}
.menu-mob {
  position: absolute;
  top: 20px;
  right: 20px;
}
.noticia .box-home{
  width: 100%;
  height: 600px;
  overflow-y: scroll;
}
.noticia .box-home .artigos-home:last-child{
  border-bottom: 0 solid #ededed;
}
.menu-mob{
  cursor: pointer;
}
.menu-mob .icone-menu {
  background-color: #363d69;
  width: 25px;
  height: 3px;
  display: block;
  margin-top: 4px;
  border-radius: 10px;
}
.display-menu-mob {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #ffffffed;
  width: 100%;
  max-width: 220px;
  height: 100vh;
  z-index: 9999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-shadow: -2px -1px 2px #b6b6b661;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  transform: translate(100%);
  transition: 1.5s;
}
.display-menu-mob ul {
  list-style: none;
  text-align: center;
  font-size: 16px;
  padding: 0;
  text-align: right
}
.display-menu-mob ul li {
  margin-top: 10px;
}

.display-menu-mob .fechar {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.display-menu-mob .fechar img {
  width: 30px;
}
.menuAberto{
  transform: translate(0);
}

@media screen and (max-width: 767px) {
  .logo-obra {
    background-size: 90%;
    width: 80px;
    height: 70px;
    margin-top: 0;
    margin-bottom: 0;
  }
  body.devir .logo-intranet {
    background-size: contain!important;
    width: 80px !important;
    height: 60px !important;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bnt-next img{
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
}
.bnt-next{
  position: absolute;
  bottom: 15px;
}
.bnt-next .anterior{
  transform: rotate(180deg);
}

.galeria-item .fotosHover{
  background-color: rgba(1, 84, 166,0.7);
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translate(-100%);
  transform: translate(-100%);
  -webkit-transition: 1s;
  transition: 1s;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 56%;
  bottom: 0;
}
.foto-item:hover .fotosHover{
  transform: translate(0);
  opacity: 1;
}

.galeria-item .fotosHover .link-fotos{
  color: #fff;
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
}

#basilar .galeria-modal {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
#basilar .galeria-modal .pesquisaBasilar .box {
  margin-top: 15px;
}
#basilar .galeria-modal .pesquisaBasilar .box .box-home {
  min-height: 600px;
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}
#basilar .galeria-modal .pesquisaBasilar .box .item-box > * {
  padding-left: 0;
  padding-bottom: 20px;
}
#basilar .galeria-modal .pesquisaBasilar .box .item-box .divBG {
  width: 100%;
  height: 30px;
  background-color: #d3d3d3;
}
#basilar .galeria-modal .pesquisaBasilar .box .item-box .divBG .divCor {
  width: 50%;
  height: 30px;
  background-color: #0154a8;
}
#basilar .galeria-modal .pesquisaBasilar .box .item-box .divBG .divCor p {
  padding: 5px;
}

#basilarObra{
  background-color: transparent;
  border: none;
}

.box .conteudo{
  width: 100%;
}
.conteudo .box-home{
  width: 100%;
  margin-bottom: 60px;
  padding: 20px;
}
.conteudo .download-conteudo .lista-download{
  list-style: none;
  padding-left: 0;
}
.conteudo .download-conteudo .lista-download li a{
  color: #fff;
  font-size: 16px;
  margin-top: 5px
}
.conteudo .download-conteudo .lista-download li:hover{
  text-decoration: underline;
}
.conteudo .bg-download{
  background-color: #0154a8;
}
.output{
  padding-top: 20px;
}

.pesquisaBasilar .box .box-home{
  width: 100%;
}
.resultado-lume .conteiner-lume img{
  box-shadow: 0 0 7px #9d9d9d;
  border-radius: 50%;
  transition: 1.5s;
}


.resultado-lume .conteiner-lume{
  transition: 1.5s;
}
.resultado-lume .conteiner-lume:hover{
  transform: scale(1.1);
}
.resultado-lume .conteiner-lume:hover img{
  box-shadow: 3px 0px 10px #9d9d9d;
}


/*# sourceMappingURL=maps/style.css.map */