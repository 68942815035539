.cadastrarParaPrograma{
    position: fixed;
    background-color: whitesmoke;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    transform: translateY(-100%);
    overflow-y: scroll;
}

.fecharApoio{
    position: absolute;
    right: 0;
    top: 0;
}

.cadastrarParaPrograma.aberto{
    transform: translateY(0)
}

.cadastrarParaPrograma > *{
    padding: 20px;
    height: 80vh;
    background-color: white;
    padding: 50px
}

.mapaDeAssentos{
    display: flex;
    padding: 10px;
    max-width: 760px;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 10px;
    justify-content: center;
}

.contentcadeiras{
    background-color: #5a5a5a;
    color: white;
    position: relative;
    background-image: url('/images/charts.png');
    background-position: bottom center, center center, bottom center;
    background-repeat: no-repeat
}


.assentoEscolhi{
    width: 100px;
    height: 100px;
    border: 1px solid #adaab0;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 20px
}

.assentoEscolhi p{
    font-size: 13px;
    line-height: 15px
}
.assentoEscolhi h5{
    margin-bottom: 0;
    text-align: center;
}

.mapaDeAssentos .assento{
    width: 50px;
    padding: 10px;
    height: 50px;
    background-image: url(/images/cadeiras/cadeira.png);
    background-position: center center;
    background-size: cover;
    margin: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 2px 1px 1px black;
}
.mapaDeAssentos .assento:hover{
    background-image: url('/images/cadeiras/cadeira-ativa.png');
}
.mapaDeAssentos .selecionado{
    border: 2px solid #30b903;
    background-image: url('/images/cadeiras/cadeira-ativa.png');
}
.mapaDeAssentos .assento.preenchido{
    border: 2px solid #bc0630;
    background-image: url('/images/cadeiras/cadeira-ocupada.png');
}
.mapaDeAssentos .assento.n_preenchido{
    background-image: url('/images/cadeiras/cadeira.png');
}
.cadastrarParaPrograma .fecharApoio{
    height: 35px;
    width: 35px;
    padding: 0;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -3px 2px 3px lightgrey;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    cursor: pointer;
}

@media screen and (max-width: 940px){
    .mapaDeAssentos{
        max-width: 780px;
    }
    .cadastrarParaPrograma > *{
        padding: 20px;
    }
}

@media screen and (max-width: 768px){
    .cadastrarParaPrograma {
        flex-wrap: wrap;
    }
    .cadastrarParaPrograma .fecharApoio{
        box-shadow: -3px 2px 3px #232323;
    }
    .cadastrarParaPrograma > *{
        padding-top: 50px;
    }
    .contentcadeiras .titulo-assento{
        text-align: left;
    }

    .informacoesDoCadastro > *{
        text-align: left;
    }
    .informacoesDoCadastro .assentoEscolhi .titulo-escolhi{
        text-align: center;
    }
}

.informacoesDoCadastro .descricao-encontro blockquote{
    margin-top: 15px;
    border-left: 5px solid #cacaca;
}

.contentcadeiras .palco{
    background-color: white;
    width: 100px;
    height: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 2px #616060;
    position: relative;
    z-index: 2;
}



.contentcadeiras .chart{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}



@media screen and (max-width: 1024px){
    .cadastrarParaPrograma > *{
        height: 95vh;
    }
    .contentcadeiras .palco{
        bottom: -33px;
    }
}

@media screen and (max-width: 1007px){
    .mapaDeAssentos{
        max-width: 760px;
    }
}

@media screen and (max-width: 761px){
    .mapaDeAssentos{
        max-width: 990px;
    }
}

@media screen and (max-width: 425px){
    .mapaDeAssentos{
        width: 100%;
        padding: 0;
        margin-bottom: 50px;
    }
    .cadastrarParaPrograma > *{
        padding: 20px;
        padding-top: 50px;
        height: auto;
    }
    .contentcadeiras .titulo-assento{
        margin-left: 5px;
    }
    .ilustrativo{
        margin-top: 15px;
    }
    .contentcadeiras .palco{
        position: relative;
        margin-top: 50px;
    }
}
